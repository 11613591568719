.icon{
    position: relative;
    top:2px;
    margin-right:10px;
}

.close{
    margin-bottom:10px;
}

.close:hover{
    cursor: pointer;
}