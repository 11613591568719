.container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
    margin:20px;
}

input[type=text]{
    padding:1.2rem;
    font-size: 1.2rem;
    border: 1px gray solid;
}

input[type=text]:focus{
    padding:1.2rem;
    font-size: 1.2rem;
    border: 1px gray solid;
}