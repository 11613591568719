* {
    box-sizing: border-box;
}

.card {
    position: relative;
    flex: 1;
    background-color: white;
    color: rgb(53, 53, 53);
    font-weight: 200;
    border-radius: 3px;
    box-shadow: 10px 31px 31px -21px rgba(0, 0, 0, 0.75);
}

.card:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.title {
   font-weight: 400;
    
    
}

.header {
    padding:7px;
}

.card img {
    width: 100%;
}

.date {
    font-size: 11px;
    font-weight: 200;
}
